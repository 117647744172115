import './App.css';
import PageLanding from './Pages/LandingPage';

function App() {
  return (
    <div className="App">
      <PageLanding />
    </div>
  );
}

export default App;
