import React from 'react';
import { Link, Box, Button, Container, Typography, AppBar, Toolbar } from '@mui/material';
import DemoComponent from '../Components/DemoComponent';
const PageLanding = () => {
  return (
    <Box>


{/* Navbar with Logo and Login Button */}
<AppBar position="static" sx={{ backgroundColor: '#333', color: '#fff' }} elevation={1}>
        <Container maxWidth="md">
          <Toolbar disableGutters variant="dense" sx={{ justifyContent: 'space-between', py: 1, minHeight: '64px' }}>
            {/* Logo with added padding */}
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
              <img src={`${process.env.PUBLIC_URL}/portorium_logo.png`} alt="Logo" style={{ height: '50px' }} />
            </Box>
            
            {/* Login Button */}
            <Button color="inherit" href="https://chromewebstore.google.com/" sx={{ color: '#fff' }}>
            DESCARGAR
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      
      {/* Sección de Título y Demo */}
      <Box sx={{ bgcolor: 'grey.900', color: 'white', py: 2 }}>
  <Container maxWidth="md">
    <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
      {/* Left Column */}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1, height: "400px" }}> 
        {/* Title */}
        <Typography variant="h3" component="h1" gutterBottom>
          Portorium
        </Typography>
        
        {/* Phrase */}
        <Box sx={{ my: 'auto' }}> {/* Centers the middle content vertically */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h5">
              Recupera tu
            </Typography>
            <Typography variant="h4" component="span" sx={{ background: 'green', color: 'white', p: '0.01em 0.5em', borderRadius: '4px', fontWeight: 'bold' }}>
                atención
            </Typography>
           
           
            
          </Box>
        </Box>
        
        {/* Subtitle */}
        <Typography variant="subtitle1">
          Bloquea las distracciones con Portorium. ¡ES POSIBLE!
        </Typography>
      </Box>

      {/* Right Column */}
      <Box sx={{ width: 'auto', marginLeft: '24px' }}> {/* Adjust width and margin as needed */}
        <Typography variant="caption" sx={{ display: 'block', marginBottom: '8px' }}>
          Prueba la demo ↓
        </Typography>
        <DemoComponent />
      </Box>
    </Box>
  </Container>
</Box>



      
    {/* Second Section: No es tu culpa */}
    <Container maxWidth="md" sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 4 }}>
          No es tu culpa, hay demasiadas distracciones
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
            Internet está lleno de distracciones que no te dejan estudiar, trabajar y tener paz mental.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h4">👨‍🎓🖥️</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Te pones a trabajar
            </Typography>
          </Box>
          <Typography variant="h5">→</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h4">😵🖥️</Typography>
            <Typography  sx={{ fontWeight: 'bold' }}>
              Página web te distrae
            </Typography>
          </Box>
          <Typography variant="h5">→</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h4">😭🖥️</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Pierdes tiempo
            </Typography>
          </Box>
        </Box>
      </Container>

      
      <Box sx={{ bgcolor: 'grey.900', color: 'white', py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" sx={{ textAlign: 'center', mb: 4 }}>
          Tu solución: Portorium
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', gap: 4 }}>
          {/* Anti-features Box */}
          <Box sx={{
            bgcolor: '#8b2c2c', // Darker red background
            p: 3,
            borderRadius: '10px',
            flex: 1
          }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'rgb(255, 166, 166)' }}>Tú sin Portorium</Typography>
            <ul style={{ listStyle: 'none', paddingLeft: 0, color: 'rgb(255, 166, 166)' }}>
              <li style={{ marginBottom: '8px' }}>✗ No puedes trabajar de manera sostenida</li>
              <li style={{ marginBottom: '8px' }}>✗ Pierdes tiempo</li>
              <li style={{ marginBottom: '8px' }}>✗ Tienes la concentración de una mosca</li>
              <li style={{ marginBottom: '8px' }}>✗ No tienes el control de tu productividad</li>
              <li style={{ marginBottom: '8px' }}>✗ Te sientes mal</li>
            </ul>
          </Box>

          {/* Features Box */}
          <Box sx={{
            bgcolor: '#2e492e', // Darker green background
            p: 3,
            borderRadius: '10px',
            flex: 1
          }}>
            <Typography variant="h6" sx={{ mb: 2, color: '#72c972' }}>Tú con Portorium</Typography>
            <ul style={{ listStyle: 'none', paddingLeft: 0, color: '#72c972' }}>
              <li style={{ marginBottom: '8px' }}>✓ Trabajas períodos prolongados</li>
              <li style={{ marginBottom: '8px' }}>✓ Vas directo al grano</li>
              <li style={{ marginBottom: '8px' }}>✓ Recuperas tu nivel de atención y concentración</li>
              <li style={{ marginBottom: '8px' }}>✓ Recuperas el control de tu productividad</li>
              <li style={{ marginBottom: '8px' }}>✓ Trabajas y te sientes mejor</li>
            </ul>
          </Box>
        </Box>
      </Container>
    </Box>


   {/* Fourth Section: Access to the Private Beta */}
<Box sx={{  py: 8, textAlign: 'center' }}>
  <Container maxWidth="md">
    <Typography variant="h5"  sx={{ mb: 4 }}>
      Descarga la extensión en la chrome store y cambia radicalmente tu manera de trabajar
    </Typography>
    <Button
      variant="contained"
      size="large"
      href="https://chromewebstore.google.com/"
      sx={{
        bgcolor: 'primary.main', // Use primary color for a standard look
        '&:hover': {
          bgcolor: 'primary.dark', // Darken the button on hover for feedback
        },
        fontSize: '1rem', // Adjust font size to standard
        padding: '10px 20px', // Standard padding for a normal appearance
      }}
    >
      DESCARGAR
    </Button>
  </Container>
</Box>
  
    </Box>
  );
};

export default PageLanding;
