import React from 'react';
import Button from '@mui/material/Button';

const DemoComponent = () => {
    return (
        <div>
            <img src="https://via.placeholder.com/400" alt="Dummy Image" />
           
        </div>
    );
};

export default DemoComponent;
